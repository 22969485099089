import Swiper from "swiper";
import 'swiper/swiper-bundle.css';
import './BannerSlider.css'
import {Autoplay} from 'swiper/modules';

class BannerSlider {
	constructor(wrapper) {
		this.wrapper = wrapper
	}
	
	init() {
		const swiper = new Swiper(this.wrapper, {
			loop: true,
			slidesPerView: `auto`,
			modules: [Autoplay],
			spaceBetween: 20,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			}
		});
	}
}

export default BannerSlider