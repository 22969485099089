import axios from 'axios'
import {application, lang, sessid} from "../../common";

class Analytics {
	url = `/local/analytics.ajax.php`;
	config = {
		headers: {
			'Content-Type': 'application/json',
			'X-Requested-With': 'XMLHttpRequest',
			'X-Bitrix-Csrf-Token': application.getSessionId()
		}
	}

	viewItemList(item_ids, list_name = '', list_id = '') {
		let body = JSON.stringify({
			event: 'viewItemList',
			event_data: {
				item_ids: item_ids,
				list_name: list_name,
				list_id: list_id
			},
		})

		axios.post(this.url, body, this.config).then(response => {
			let event_data = response.data.event_data
			console.info(event_data)
			dataLayer.push({ecommerce: null});
			dataLayer.push({
				event: "view_item",
				ecommerce: event_data
			})
		})
	}

	viewItemListPromise (item_ids, list_name = '', list_id = '') {
		return new Promise(resolve => {
			let body = JSON.stringify({
				event: 'viewItemList',
				event_data: {
					item_ids: item_ids,
					list_name: list_name,
					list_id: list_id,
				},
				lang: lang,
				sessid: sessid
			})

			axios.post(this.url, body, this.config)
				.then(response => {
					resolve(response.data)
				})
				.catch(reason => {
					console.log(reason)
					resolve()
				})
		})
	}
	
	viewItem(item_ids, list_name = '', list_id = '') {
		console.info('ga / viewItem')
		let body = JSON.stringify({
			event: 'viewItem',
			event_data: {
				item_ids: item_ids,
				list_name: list_name,
				list_id: list_id
			},
		})
		
		axios.post(this.url, body, this.config).then(response => {
			let event_data = response.data.event_data
			console.info(event_data)
			dataLayer.push({ecommerce: null});
			dataLayer.push({
				event: "view_item",
				ecommerce: event_data
			})
		})
	}
	
	addToCart(item_id) {
		console.info('ga / addToCart')
		let body = JSON.stringify({
			event: 'addToCart',
			event_data: {
				item_id: item_id
			},
		})
		
		axios.post(this.url, body, this.config).then(response => {
			let event_data = response.data.event_data
			console.info(event_data)
			dataLayer.push({ecommerce: null});
			dataLayer.push({
				event: "add_to_cart",
				ecommerce: event_data
			})
		})
	}
	
	beginCheckout() {
		console.info('ga / beginCheckout')
		let body = JSON.stringify({event: 'beginCheckout'})
		
		axios.post(this.url, body, this.config).then(response => {
			let event_data = response.data.event_data
			console.info(event_data)
			dataLayer.push({ecommerce: null});
			dataLayer.push({
				event: "begin_checkout",
				ecommerce: event_data
			})
		})
	}

	selectItem (item_id, list_name = '', list_id = '') {
		let body = JSON.stringify({
			event: 'selectItem',
			event_data: {
				item_id: item_id,
				list_name: list_name,
				list_id: list_id,
			},
			lang: lang,
			sessid: sessid
		})

		return axios.post(this.url, body, this.config)
	}
}

export default Analytics