'use strict';
import {eventEmitter} from "devSrc/common";
import {CatalogCurrency} from "devSrc/common";
import {application} from "devSrc/common";

export class AddToCartWindow {
	/**  @param {Object} params */
	constructor(params) {
		this.param = params.params;
		this.node = null;
		this.fuserId = params.fuserId;
		this.successful = params.successful;
		this.product = params.product;
		this.productCode = params.productCode;
		this.basket = {};
		this.productFields = params.productFields;
		this.messages = (params.hasOwnProperty('messages')) ? params.messages : {};
		this.defaultPicture = params.defaultPicture;
		this.nodeId = 'product_popup_add_cart_' + this.product.ID;
		this.quantityUp = null;
		this.quantityDown = null;
		this.quantityInput = null;
		this.closeButton = null;
		this.continueButton = null;
		this.priceBlock = null;
		this.totalPriceNode = null;
		this.statusNode = null;
		this.processing = false;
		this.ajaxPath = params.ajaxPath;
		this.paramPath = params.paramPath;
		this.currentQuantity = 1;
		this.siteId = params.siteId;
		this.currency = params.baseCurrency;
		this.cleaning();
		this.sendRequest('checkBasket');
	}
	
	/**
	 *
	 * @param {Object} data
	 */
	set messages(data) {
		this._messages = data;
	}
	
	/**
	 *
	 * @param {string} code
	 * @returns {string}
	 */
	message(code) {
		return this._messages.hasOwnProperty(code) ? this._messages[code] : '';
	}
	
	show() {
		let div = document.createElement('div');
		div.className = 'popup popup-add-cart active';
		div.id  = this.nodeId;
		
		let overlay = document.createElement('div');
		overlay.className = 'popup__overlay';
		let holder = document.createElement('div');
		holder.className = 'popup__holder popup__holder--add-cart';
		this.getContent().forEach(el => {
			holder.append(el);
		})
		
		div.append(overlay)
		div.append(holder);
		this.node = div;
		
		document.body.appendChild(this.node);
		
		this.closeButton.addEventListener('click', () => {
			this.removeWindow();
		});
		
		this.continueButton.addEventListener('click', () => {
			this.removeWindow()
		})
		
		if (this.successful) this.numberPicker();
	}
	
	cleaning() {
		let windowList = document.querySelectorAll('.popup-add-cart');
		
		if (windowList.length <= 0) return;
		
		windowList.forEach( (item) => {
			item.remove();
		});
	}
	
	numberPicker() {
		let min = parseInt(this.quantityInput.getAttribute('min'), 10);
		let max = parseInt(this.quantityInput.getAttribute('max'), 10);
		
		if (max === 0 || isNaN(max)) max = Infinity;
		let self = this;
		let inputFunc = function inputFunc() {
			if (self.processing) return;
			let i = parseInt(self.quantityInput.value, 10);
			
			if (self.node instanceof HTMLElement) {
				let oldMessage = self.node.querySelector('#full_elm');
				if (oldMessage instanceof HTMLElement)
					oldMessage.parentNode.removeChild(oldMessage);
			}
			
			if (max === 1) {
				self.quantityUp.disabled = true;
				self.quantityDown.disabled = true;
				
				let elm = document.getElementById('add-cart__options');
				let maximum = document.createElement('div');
				maximum.id = "full_elm";
				maximum.className = "maximum";
				maximum.innerHTML = `<p class='error' style='font-size: 70%; width: 300px;'>${self.message('ADD_CART_QUANTITY_LIMITED')}</p>`;
				elm.append(maximum);
			} else if (i <= min || !i) {
				self.quantityInput.value = min;
				self.quantityUp.disabled = false;
				self.quantityDown.disabled = true;
				let btn = document.getElementById('product-quantity__plus');
				if (btn) {
					btn.style.display = 'block';
				}
			} else if (i >= max) {
				self.quantityInput.value = max;
				self.quantityUp.disabled = true;
				self.quantityDown.disabled = false;
				
				let btn = document.getElementById('product-quantity__plus');
				let elm = document.getElementById('add-cart__options');
				let maximum = document.createElement('div');
				maximum.id = "full_elm";
				maximum.className = "maximum";
				maximum.innerHTML = `<p class='error' style='font-size: 70%; width: 300px;'>${self.message('ADD_CART_QUANTITY_LIMITED')}</p>`;
				elm.append(maximum);
				btn.style.display = 'none';
				
			} else {
				self.quantityUp.disabled = false;
				self.quantityDown.disabled = false;
				let btn = document.getElementById('product-quantity__plus');
				if (btn) {
					btn.style.display = 'block';
				}
				
			}
			
			if (Number(self.currentQuantity) !== Number(self.quantityInput.value))
				self.sendRequest('changeQuantity');
			
		};
		
		let changeFunc = function changeFunc(qty) {
			if (self.processing) return;
			let q = parseInt(qty, 10);
			let i = parseInt(self.quantityInput.value, 10);
			if (i < max && q > 0 || i > min && !(q > 0)) {
				self.quantityInput.value = i + q;
				inputFunc();
			}
		};
		
		this.quantityDown.addEventListener('click', () => {
			changeFunc(-1);
		})
		this.quantityUp.addEventListener('click', () => {
			changeFunc(1)
		});
		this.quantityInput.addEventListener('change', () => {
			inputFunc()
		});
		inputFunc();
	}
	
	/**
	 *
	 * @param {string} type
	 */
	sendRequest(type) {
		if (this.processing) return;
		this.processing = true;
		this.addLoader();
		
		type = !!type ? type : 'changeQuantity';
		
		let currentQuantity = this.currentQuantity;
		let upQuantity = this.currentQuantity;
		if (type === 'changeQuantity' && this.quantityInput instanceof HTMLElement)
			upQuantity = Number(this.quantityInput.value) > 0 ? Number(this.quantityInput.value) : 0;
		
		let self = this;
		let errors = [];
		let data = {};
		let formData = new FormData();
		data.fuserId = application.getFuserId();
		data.sessid = application.getSessionId();
		data.productId = this.product.ID;
		data.quantity = upQuantity;
		data['SALE_PRODUCT_QUANTITY_UPDATE'] = 'Y';
		data.type = type;
		data.siteId = application.getSiteId();
		data.lang = application.getLanguageId();
		
		//Generate form
		for (let k in data) {
			if(data.hasOwnProperty(k))
				formData.append(k, data[k]);
		}
		
		fetch(this.ajaxPath,{
			method: 'POST',
			mode: 'same-origin',
			cache: 'no-cache',
			body: formData
		})
			.then(result => {
				// отправка события обновления количества товара в попап окне
				let direction = !isNaN(currentQuantity) && Number(upQuantity) > Number(currentQuantity) ? 'up' : 'down'
				eventEmitter.emit('OnUpdateItemQuantity', {productId: data.productId, direction: direction})
				
				if (result.status !== 200) {
					if (type === 'checkBasket') self.show();
					self.processing = false;
					self.removeLoader();
				} else return result.json();
			})
			.then(result => {
				if (result.hasOwnProperty('BASKET')) self.basket = result.BASKET;
				if (type === 'checkBasket') {
					if (self.basket.hasOwnProperty('QUANTITY')) self.currentQuantity = Number(self.basket.QUANTITY);
					self.processing = false;
					self.removeLoader();
					self.show();
				} else if (type === 'changeQuantity') {
					
					if (result.hasOwnProperty('CHANGE_QUANTITY') && result.CHANGE_QUANTITY) {
						self.currentQuantity = Number(self.basket.QUANTITY);
						eventEmitter.emit('OnBasketChange');
					} else {
						
						self.quantityUp.disabled = true;
						let btn = document.getElementById('product-quantity__plus');
						let elm = document.getElementById('add-cart__options');``
						let maximum = document.createElement('div');
						maximum.id = "full_elm";
						maximum.className = "maximum";
						maximum.innerHTML = `<p class='error' style='font-size: 70%; width: 300px;'>${self.message('ADD_CART_QUANTITY_LIMITED')}</p>`;
						elm.append(maximum);
						btn.style.display = 'none';
					}
					
					
					if (result.hasOwnProperty('ERROR')) {
					
					} else if (!self.isEmptyObject(self.basket)) {
						let price = [];
						
						if (Number(self.basket.DISCOUNT_PRICE) > 0) {
							let priceNewNode = document.createElement('div');
							priceNewNode.className = 'product__price-new';
							priceNewNode.innerHTML =  CatalogCurrency.currencyFormat(self.basket.PRICE, self.currency, true);
							
							price.push(priceNewNode);
							
							let priceOldNode = document.createElement('div');
							priceOldNode.className = 'product__price-old';
							priceOldNode.innerHTML =  CatalogCurrency.currencyFormat(self.basket.BASE_PRICE, self.currency, true);
							
							price.push(priceOldNode);

							self.totalPriceNode.innerHTML = CatalogCurrency.currencyFormat(self.basket.DISCOUNT_PRICE * self.currentQuantity, self.currency, true);
						} else {
							let priceNode = document.createElement('div');
							priceNode.className = 'price';
							priceNode.innerHTML =  CatalogCurrency.currencyFormat(self.basket.PRICE, self.currency, true)
							
							price.push(priceNode);

							self.totalPriceNode.innerHTML = CatalogCurrency.currencyFormat(self.basket.PRICE * self.currentQuantity, self.currency, true);
						}
					}
				}
				self.processing = false;
				self.removeLoader();
			})
	}
	
	/**
	 * Return array with DOMElements
	 * @returns {*[]}
	 */
	getContent() {
		let result = [];
		let content, image, cart;
		let price = [];
		let totalPrice = '';
		let article = null;
		let image_data = null;
		
		if (this.product.hasOwnProperty('IMAGES') && this.product['IMAGES'].length > 0) {
			if (this.product['IMAGES'][0][238]) {
				image_data = this.product['IMAGES'][0][238];
			} else {
				image_data = this.product['IMAGES'][0];
			}
			
			image = document.createElement('div');
			image.className = 'add-cart__image';
			let imageChild = document.createElement('img');
			imageChild.src = image_data.SRC;
			imageChild.width = Number(image_data.WIDTH);
			imageChild.height = Number(image_data.HEIGHT);
			image.append(imageChild);
		}
		
		
		if (this.productCode.length > 0) {
			article = document.createElement('div');
			article.className = 'add-cart__article';
			article.innerHTML = this.message('ADD_CART_ARTICLE') + ': <span>' + this.productCode + '</span>';
		}
		else {
			article = ''
		}
		
		if (this.successful) {
			if (!this.isEmptyObject(this.basket)) {
				
				if (Number(this.param.DISCOUNT_PRICE) != Number(this.param.BASE_PRICE)) {
					price.push(BX.create('div', {
						props: {className: 'product__price-new'},
						html: CatalogCurrency.currencyFormat(this.param.DISCOUNT_PRICE, this.currency, true)
					}));
					price.push(BX.create('div', {
						props: {className: 'product__price-old'},
						html: CatalogCurrency.currencyFormat(this.param.BASE_PRICE, this.currency, true)
					}));
				} else {
					price.push(BX.create('div', {
						props: {className: 'price'},
						html: CatalogCurrency.currencyFormat(this.param.DISCOUNT_PRICE, this.currency, true)
					}));
				}
				
				totalPrice = CatalogCurrency.currencyFormat(this.param.DISCOUNT_PRICE * this.currentQuantity, this.currency, true);
				
			}
			
			if (totalPrice.length <= 0) totalPrice = CatalogCurrency.currencyFormat(this.product.ITEM_PRICES[this.product.ITEM_PRICE_SELECTED].PRICE * this.currentQuantity, this.currency, true);
			
			let max = this.param.QUANTITY;
			cart = BX.create('div', {
				props: {className: 'add-cart__content'}, children: [
					BX.create('div', {
						style: {visibility: 'hidden'},
						props: {className: 'container basket item'},
						html: '<div data-productkey="' + this.product['ID'] + '"></div>'
					}),
					BX.create('div', {props: {className: 'add-cart__title'}, text: this.productFields.name}),
					this.statusNode = BX.create('div', {
						props: {className: 'add-cart__status'},
						html: '<span></span> ' + this.message('ADD_CART_STATUS_OK')
					}),
					article,
					BX.create('div', {
						props: {className: 'add-cart__options'},
						attrs: {
							id: 'add-cart__options'
						},
						children: [
							BX.create('div', {
								props: {className: 'add-cart__options-item'}, children: [
									BX.create('div', {
										props: {className: 'add-cart__options-text'},
										text: this.message('ADD_CART_PRICE_TITLE') + ':'
									}),
									this.priceBlock = BX.create('div', {
										props: {className: 'add-cart__options-value'},
										children: price
									})
								]
							}),
							BX.create('div', {
								props: {className: 'add-cart__options-item'}, children: [
									BX.create('div', {
										props: {className: 'add-cart__options-text'},
										text: this.message('ADD_CART_QUANTITY_TITLE') + ':'
									}),
									BX.create('div', {
										props: {className: 'add-cart__options-value'}, children: [
											BX.create('div', {
												props: {className: 'product-quantity'}, children: [
													this.quantityDown = BX.create('button', {
														props: {className: 'product-quantity__button product-quantity__minus'},
														html: '<svg xmlns="http://www.w3.org/2000/svg" width="5" height="6" viewBox="0 0 5 6"><path d="M547,1782.72l-1.894-2.94,1.894-2.63-0.173-.15-4.83,2.78,4.97,3.22Z" transform="translate(-542 -1777)"/></svg>'
													}),
													
													this.quantityInput = BX.create('input', {
														props: {className: 'product-quantity__input'},
														attrs: {
															type: 'number',
															'readonly': 'readonly',
															name: 'product-quantity',
															value: this.currentQuantity,
															min: 1,
															max: Number(this.param.QUANTITY)
														}
													}),
													
													this.quantityUp = BX.create('button', {
														props: {className: 'product-quantity__button product-quantity__plus'},
														attrs: {
															id: 'product-quantity__plus'
														},
														html: '<svg xmlns="http://www.w3.org/2000/svg" width="5" height="6" viewBox="0 0 5 6"><path d="M547,1782.72l-1.894-2.94,1.894-2.63-0.173-.15-4.83,2.78,4.97,3.22Z" transform="translate(-542 -1777)"/></svg>',
														
													}),
												
												]
											})
										]
									})
								]
							}),
							BX.create('div', {
								props: {className: 'add-cart__options-item'}, children: [
									BX.create('div', {
										props: {className: 'add-cart__options-text'},
										text: this.message('ADD_CART_TOTAL_TITLE') + ':'
									}),
									BX.create('div', {
										props: {className: 'add-cart__options-value'}, children: [
											this.totalPriceNode = BX.create('div', {
												props: {className: 'total'},
												html: totalPrice
											})
										]
									})
								]
							})
						]
					}),
					
					BX.create('div', {
						props: {className: 'add-cart__buttons'}, children: [
							this.continueButton = BX.create('a', {
								props: {className: 'white-btn'},
								attrs: {href: '#', onclick: 'return false;'},
								text: this.message('ADD_CART_BUTTON_CONTINUE')
							}),
							BX.create('a', {
								props: {className: 'black-btn'},
								attrs: {href: application.getLanguageId() !== 'ru' ? '/' + application.getLanguageId() + '/personal/order/make/' : '/personal/order/make/'},
								text: this.message('ADD_CART_BUTTON_ORDER')
							})
						]
					})
				
				]
			});
			
		} else {
			this.statusNode = document.createElement('div');
			this.statusNode.className = 'add-cart__status';
			this.statusNode.innerHTML = '<span></span> ' + this.message('ADD_CART_STATUS');
			
			this.continueButton = document.createElement('a');
			this.continueButton.className = 'white-btn';
			this.continueButton.href = '#';
			this.continueButton.onclick = 'return false';
			this.continueButton.textContent = this.message('ADD_CART_BUTTON_CONTINUE');

			cart = document.createElement('div');
			cart.className = 'add-cart__content';
			let cartTitle = document.createElement('div');
			cartTitle.className = 'add-cart__title';
			cartTitle.textContent = this.productFields.name;
			
			let cartButtons = document.createElement('div');
			cartButtons.className = 'add-cart__buttons';
			cartButtons.append(this.continueButton)
			
			cart.append(cartTitle);
			cart.append(this.statusNode);
			cart.append(article);
			cart.append(cartButtons);
		}
		
		content = document.createElement('div');
		content.className = 'add-cart';
		content.append(image);
		content.append(cart);
		
		this.closeButton = document.createElement('div');
		this.closeButton.className = 'popup__close';
		this.closeButton.innerHTML = '<svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/images/sprite.svg#close"></use></svg>';
		
		
		result.push(this.closeButton);
		result.push(content);
		return result;
	}
	
	removeWindow() {
		let node = document.getElementById(this.nodeId);
		if (node) node.remove();
	}
	
	isEmptyObject(obj) {
		if (typeof obj !== 'object') return;
		for (let i in obj) {
			if (obj.hasOwnProperty(i)) {
				return false;
			}
		}
		return true;
	}
	
	addLoader() {
		if (!document.getElementById('lds-ring')) {
			let div = document.createElement('div');
			div.className = "lds-ring";
			div.id = 'lds-ring';
			div.style.width = document.body.offsetWidth + 'px';
			div.style.height = document.body.offsetHeight + 'px';
			div.innerHTML = '<div></div><div></div><div></div><div></div>';
			document.body.prepend(div);
		}
	}
	
	removeLoader() {
		let loader = document.getElementById('lds-ring');
		if (loader instanceof HTMLElement) {
			loader.remove();
		}
	}
}