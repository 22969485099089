'use strict';

class PromotionalBanner {
    constructor(params) {
        this.bannerBlockNode = params.node;
        this.images = params.images;
        this.fields = params.fields;
        this.documentWidth = Number(document.documentElement.clientWidth);
        this.currentScreenResolution = '1920x1080';
        this.imgNode = null;
        this.sizeData = {
            '1920x1080' : {from : 1000000, to : 1366},
            '1366x768' : {from : 1366, to : 1024},
            '1024x768' : {from : 1024, to : 736},
            '320x568' : {from : 736, to : 0}
        };


        this.bannerDisplay();
        this.bindEvents();
    }

    checkScreenResolution(isEvent) {
        this.documentWidth = Number(document.documentElement.clientWidth);
        let oldScreenResolution = this.currentScreenResolution;
        for(let resolution in this.sizeData) {
            if(!this.sizeData.hasOwnProperty(resolution)) continue;
            if(this.documentWidth < this.sizeData[resolution].from && this.documentWidth > this.sizeData[resolution].to) {
                this.currentScreenResolution = resolution;
                break;
            }
        }

        if(isEvent && oldScreenResolution !== this.currentScreenResolution) this.updateBannerImage();
    }

    updateBannerImage() {
        if(!this.bannerBlockNode instanceof HTMLElement) return false;
        let imgLink = '';
        if(!this.imgNode) this.imgNode = this.bannerBlockNode.querySelector('img');
        if(!this.imgNode) return false;

        if(this.images.hasOwnProperty(this.currentScreenResolution))
            imgLink = this.images[this.currentScreenResolution];
        else
            imgLink = this.images['1920x1080'];

        this.imgNode.setAttribute('resolution', this.currentScreenResolution);

        this.imgNode.src = imgLink;
    }

    bindEvents() {
        let self = this;
        window.addEventListener('resize', function (e) {
            self.checkScreenResolution(true);
        });
    }

    bannerDisplay() {
        if(!this.bannerBlockNode instanceof HTMLElement || !this.images.hasOwnProperty('1920x1080')) return false;
        this.checkScreenResolution(false);
        let bannerHTML = '';
        let imgLink = '';
        if(this.images.hasOwnProperty(this.currentScreenResolution))
            imgLink = this.images[this.currentScreenResolution];
        else
            imgLink = this.images['1920x1080'];

        bannerHTML = '<img src="'+imgLink+'" width="1480" height="257" alt="" data-resolution=\''+this.currentScreenResolution+'\' loading="lazy">';
        bannerHTML += '<div class="promotional-block__content">';

        if(this.fields.hasOwnProperty('SUBTITLE')) bannerHTML += '<div class="promotional-block__subtitle">'+this.fields['SUBTITLE']+'</div>';
        if(this.fields.hasOwnProperty('TITLE')) bannerHTML += '<div class="promotional-block__title"><span>'+this.fields['TITLE']+'</span></div>';
        if(this.fields.hasOwnProperty('PRICE')) {
            bannerHTML += '<div class="promotional-block__price-box">' +
                '<div class="promotional-block__price-new">'+this.fields['PRICE']+'</div>';

            if(this.fields.hasOwnProperty('OLD_PRICE'))
                bannerHTML += '<div class="promotional-block__price-old">'+this.fields['OLD_PRICE']+'</div>';

            bannerHTML += '</div>';
        }

        bannerHTML += '</div>';


        this.bannerBlockNode.innerHTML = bannerHTML;
    }

    isNotEmptyObject() {
        for (let i in obj) {
            if (obj.hasOwnProperty(i)) {
                return true;
            }
        }
        return false;
    }
}

export {PromotionalBanner};