'use strict';

import 'slick-carousel'

class ServiceSlider {
    static init() {
        let $slickElement = $('.main-service__slider');
        let $status = $('.main-service__counter');
        $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            let i = (currentSlide || 0) + 1;
            $status.html('<span class="slide-current"> 0' + i + '</span>\n       <span class="slide-all">/ 0' + slick.slideCount + ' </span>');
        });

        $slickElement.slick({
            slidesToShow: 1,
            speed: 800,
            arrows: true,
            fade: true,
            dots: false,
            rows: 0,
            autoplay: true,
            autoplaySpeed: 5000,
            prevArrow: $('.main-service__prev').html('<div class="slider__prev-btn"><svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="40.937" viewBox="0 0 21.75 40.937"><path d="M198.656,500.682l18.569-18.5a1.151,1.151,0,0,0,0-1.639,1.163,1.163,0,0,0-1.646,0l-19.4,19.322a1.152,1.152,0,0,0,0,1.64l19.4,19.314a1.174,1.174,0,0,0,.819.343,1.139,1.139,0,0,0,.818-0.343,1.152,1.152,0,0,0,0-1.64Z" transform="translate(-195.844 -480.219)"/></svg></div>'),
            nextArrow: $('.main-service__next').html('<div class="slider__next-btn"><svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="40.937" viewBox="0 0 21.75 40.937"><path d="M1721.34,500.682l-18.57-18.5a1.163,1.163,0,1,1,1.65-1.639l19.4,19.322a1.165,1.165,0,0,1,0,1.64l-19.4,19.314a1.16,1.16,0,1,1-1.64-1.64Z" transform="translate(-1702.44 -480.219)"/></svg></div>')
        });
    }
}

export {ServiceSlider};